// @flow
import { NavLink } from "react-router-dom";
import { css } from "styled-components";
import { NestedLogo } from "@nested/brand/src/icons";
import { media } from "../../media";

const navWrapper = css`
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
`;

const mainNavArea = css`
  flex-grow: 1;
`;

const logoWrapper = css`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const logoStyle = css`
  height: 29px;
  ${media.tablet`
    height: 32px;
  `}
`;

const technologyLogoStyle = css`
  color: ${({ theme }) => theme.palette.hague70};
  font-size: 18px;
  line-height: 22px;
  margin-left: 20px;
  margin-top: 3px;
`;

export const TechCareersNavigation = () => {
  return (
    <div css={navWrapper}>
      <div css={mainNavArea}>
        <NavLink to="/" css={logoWrapper}>
          <NestedLogo css={logoStyle} />
          <span css={technologyLogoStyle}>technology</span>
        </NavLink>
      </div>
    </div>
  );
};
